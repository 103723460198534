<template>
  <div>
    <div
      v-if="menuOpen && !isWideScreen"
      class="fixed top-0 left-0 w-full h-full z-10 bg-black opacity-50"
      @click="toggleMenu"
    ></div>
    <div
      class="bg-sagocolor text-sagotextcolor shadow-2xl fixed w-full z-50 top-0 h-24"
    >
      <div
        class="py-3 flex flex-row justify-between items-center h-full lg:px-16"
      >
        <!-- Left hand side -->
        <div class="flex items-center w-full">
          <div
            class="text-2xl sm:text-3xl font-bold font-quicksand cursor-pointer ml-4 flex-row flex items-center"
            @click="navigateToRoot"
          >
            <img
              src="/sagostund_logo.png"
              alt="Sagostund Logo"
              class="rounded-full w-16 h-16 mr-4 border p-1"
            />
            <div class="hidden lg:block">
              {{ translations["Sagostund.nu"][currentLang] }}
            </div>
          </div>
          <div
            v-if="currentUser !== null"
            class="flex flex-row items-center justify-center ml-4"
          >
            <button
              @click="buyTokens"
              class="button px-4 py-2 rounded transform active:scale-95 bg-green-700 hover:bg-green-600 text-white"
            >
              <font-awesome-icon class="mr-1" :icon="['fas', 'plus']" />
              <font-awesome-icon :icon="['fas', 'coins']" />
            </button>
            <div class="text-xl font-bold font-quicksand p-3">
              {{ sagoTokens }}
            </div>
            <font-awesome-icon :icon="['fas', 'coins']" />
          </div>
        </div>

        <!-- Right hand side -->
        <div
          v-if="showHeader && (menuOpen || isWideScreen)"
          :class="
            menuOpen
              ? 'absolute top-full left-0 right-0 z-20 bg-sagocolor p-6 space-y-2'
              : 'flex space-x-4 sm:items-center justify-end w-full px-5'
          "
        >
          <router-link
            v-for="route in protectedRoutes"
            :key="route.name"
            :to="route.path"
            @click="toggleMenu"
            :class="
              route.meta.greenButton
                ? 'button px-4 py-2 min-w-fit rounded block text-center transform active:scale-95  bg-green-700 hover:bg-green-600 text-white'
                : 'hover:underline block text-center min-w-fit'
            "
          >
            {{ formatRouteName(route.name) }}
          </router-link>
          <router-link
            v-for="route in authorizedRoutes"
            :key="route.name"
            :to="route.path"
            @click="toggleMenu"
            class="hover:underline block text-center min-w-fit"
          >
            {{ formatRouteName(route.name) }}
          </router-link>

          <button
            v-if="currentUser === null"
            @click="signIn"
            class="border rounded px-4 py-2 mt-4 sm:mt-0 block text-center mx-auto active:scale-95 min-w-fit hover:bg-slate-600"
          >
            {{ translations["Logga in"][currentLang] }}
          </button>
          <button
            v-else
            @click="logout"
            class="border rounded px-4 py-2 mt-4 sm:mt-0 block text-center mx-auto active:scale-95 min-w-fit hover:bg-red-800 hover:text-white"
          >
            {{ translations["Logga ut"][currentLang] }}
          </button>

          <button
            v-if="currentUser === null"
            @click="signUp"
            class="border rounded px-4 py-2 mt-4 sm:mt-0 block text-center mx-auto active:scale-95 min-w-fit hover:bg-slate-600"
          >
            {{ translations["Skapa konto"][currentLang] }}
          </button>
          <button
            v-if="currentUser !== null"
            @click="navigateToProfile"
            class="rounded sm:px-4 sm:py-2 mt-4 sm:mt-0 block text-center mx-auto active:scale-95 min-w-fit"
          >
            <font-awesome-icon
              :icon="['fas', 'user-circle']"
              size="2x"
              class="hover:opacity-80"
            />
          </button>
          <div>
            <button
              @click="open = !open"
              class="inline-flex w-full justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
              id="menu-button"
              aria-expanded="true"
              aria-haspopup="true"
            >
              <div class="flex justify-center items-center">
                <country-flag
                  :country="currentLang == 'sv' ? 'se' : currentLang"
                  size="small"
                />
                {{ currentLang == "sv" ? "SE" : currentLang.toUpperCase() }}
              </div>
            </button>
          </div>

          <div
            v-if="open"
            class="origin-top-right absolute rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="menu-button"
            tabindex="-1"
          >
            <div class="py-1" role="none">
              <!-- Language options-->
              <a
                href="#"
                class="text-gray-700 block px-4 py-2 text-sm"
                role="menuitem"
                tabindex="-1"
                id="menu-item-0"
                @click="changeLanguage('sv')"
              >
                <country-flag country="se" size="small" /> SE
              </a>
              <a
                href="#"
                class="text-gray-700 block px-4 py-2 text-sm"
                role="menuitem"
                tabindex="-1"
                id="menu-item-1"
                @click="changeLanguage('pl')"
              >
                <country-flag country="pl" size="small" /> PL
              </a>
            </div>
          </div>
        </div>

        <div v-if="!isWideScreen" class="relative z-10 block mr-5">
          <button @click="toggleMenu">
            <Bars3Icon class="w-10 h-10" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { signOut } from "firebase/auth";
import { auth, db } from "@/firebase";
import { doc, runTransaction, getDoc, setDoc } from "firebase/firestore";
import { Bars3Icon } from "@heroicons/vue/24/solid";
import translations from "@/trans";
import { updateRoutes } from "@/router";
import CountryFlag from "vue-country-flag-next";

export default {
  components: {
    Bars3Icon,
    CountryFlag,
  },
  data() {
    return {
      translations,
      menuOpen: false,
      showHeader: true,
      // isWideScreen: window.innerWidth > 640,
      currentUser: null,
      isWideScreen: window.innerWidth > 1280,
      open: false,
    };
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
  },
  onUnmounted() {
    window.removeEventListener("resize", this.handleResize);
  },
  computed: {
    sagoTokens() {
      return this.$store.state.sagoTokens;
    },
    currentLang() {
      return this.$store.state.language;
    },
    protectedRoutes() {
      return this.$store.state.routes.filter(
        (route) => route.meta && route.meta.header
      );
    },
    authorizedRoutes() {
      return this.$store.state.routes.filter(
        (route) =>
          route.meta && route.meta.authHeader && this.currentUser !== null
      );
    },
    showButtons() {
      return this.currentUser !== null;
    },
  },
  methods: {
    changeLanguage(lang) {
      // Change language logic here
      console.log("Language changed to:", lang);
      this.$store.dispatch("updateLanguage", lang);
      updateRoutes();
      this.open = false;
    },
    handleResize() {
      this.isWideScreen = window.innerWidth > 1280;
    },
    toggleMenu() {
      if (this.isWideScreen) {
        this.menuOpen = false;
      } else {
        this.menuOpen = !this.menuOpen;
      }
    },
    navigateToRoot() {
      this.menuOpen = false;
      this.$router.push("/");
    },
    navigateToProfile() {
      this.menuOpen = false;
      console.log("Navigating to profile");
      this.$router.push(translations["/profil"][this.currentLang]);
    },
    signIn() {
      this.menuOpen = false;
      this.$router.push(translations["/logga-in"][this.currentLang]);
    },
    signUp() {
      this.menuOpen = false;
      this.$router.push(translations["/skapa-konto"][this.currentLang]);
    },
    buyTokens() {
      this.menuOpen = false;
      this.$router.push(translations["/köp-sagomynt"][this.currentLang]);
    },
    formatRouteName(name) {
      return name.charAt(0).toUpperCase() + name.slice(1);
    },
    async logout() {
      try {
        await signOut(auth);
        this.$router.push("/");
      } catch (error) {
        console.error("Error signing out: ", error);
      }
    },
    async rewardUser(user) {
      const userDocRef = doc(db, "users", user.uid);

      try {
        await runTransaction(db, async (transaction) => {
          const userDoc = await transaction.get(userDocRef);

          if (!userDoc.exists()) {
            throw new Error("User does not exist!");
          }

          const currentTokens = userDoc.data().sagoTokens || 0;
          const newTokens = currentTokens + 10;

          transaction.update(userDocRef, { sagoTokens: newTokens });
        });

        this.$store.dispatch("addNotification", {
          message:
            translations[
              "Eftersom det är första gången du loggar in idag har vi fyllt på ditt konto med 10 sagomynt!"
            ][this.currentLang],
        });
      } catch (error) {
        console.error("Transaction failed:", error);
        // Handle the error as appropriate, e.g., notify the user
      }
    },
  },
  created() {
    auth.onAuthStateChanged(async (user) => {
      this.currentUser = user;

      if (user) {
        const today = new Date().setHours(0, 0, 0, 0); // Reset time to midnight
        const usersRef = doc(db, "users", user.uid);

        const userDoc = await getDoc(usersRef);
        if (
          !userDoc.exists() ||
          !userDoc.data().reward ||
          userDoc.data().reward.toDate().setHours(0, 0, 0, 0) !== today
        ) {
          // The user hasn't been rewarded today
          this.rewardUser(user);

          // Update the Firestore document with today's date
          await setDoc(usersRef, { reward: new Date() }, { merge: true });
        }
      }
    });
  },
};
</script>
