import { createApp } from "vue";
import App from "@/App.vue";
import store from "@/store";
import router from "@/router";
import { auth } from "@/firebase";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faPhone,
  faEnvelope,
  faArrowUp,
  faCircle,
  faCoins,
  faPlus,
  faArrowsRotate,
  faArrowRight,
  faArrowLeft,
  faShare,
  faShareFromSquare,
  faCopy,
  faVolumeUp,
  faUserCircle,
  faPenToSquare,
  faUpload,
  faBook,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import {
  faGoogle,
  faFacebookF,
  faFacebook,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(
  faEnvelope,
  faPhone,
  faArrowUp,
  faGoogle,
  faFacebookF,
  faFacebook,
  faCircle,
  faCoins,
  faPlus,
  faArrowRight,
  faArrowLeft,
  faArrowsRotate,
  faShare,
  faShareFromSquare,
  faCopy,
  faVolumeUp,
  faUserCircle,
  faPenToSquare,
  faUpload,
  faBook,
  faXmark
);

const app = createApp(App).use(store);

app.component("font-awesome-icon", FontAwesomeIcon);

let mountedApp; // This will hold the instance once it's created

auth.onAuthStateChanged(() => {
  if (!mountedApp) {
    app.use(router);
    mountedApp = app.mount("#app");
  }
});
