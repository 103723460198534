import { createRouter, createWebHistory } from "vue-router";
import { auth } from "@/firebase";
import translations from "./trans";
import store from "@/store";

// Import the views
const HomeView = () => import("@/views/HomeView.vue");
const CreateView = () => import("@/views/CreateView.vue");
const BuySagoTokensView = () => import("@/views/BuySagoTokensView.vue");
const LibraryView = () => import("@/views/LibraryView.vue");
const AuthorsView = () => import("@/views/AuthorsView.vue");
const CreateAccountView = () => import("@/views/auth/CreateAccountView.vue");
const LoginView = () => import("@/views/auth/LoginView.vue");
const ForgottenPasswordView = () =>
  import("@/views/auth/ForgottenPasswordView.vue");
const ProfileView = () => import("@/views/ProfileView.vue");
const ClassicStoryView = () => import("@/views/subviews/ClassicStoryView.vue");
const MyStoryView = () => import("@/views/subviews/MyStoryView.vue");
const CreatingStoryView = () =>
  import("@/views/subviews/CreatingStoryView.vue");
const PublicStoryView = () => import("@/views/subviews/PublicStoryView.vue");
const SharedStoryView = () => import("@/views/subviews/SharedStoryView.vue");
const BookOrderedView = () => import("@/views/subviews/BookOrderedView.vue");

function generateRoutes() {
  const currentLang = store.state.language;

  return [
    {
      path: "/",
      name: "Hem",
      component: HomeView,
      meta: { header: false },
    },
    {
      path: translations["/skapa-din-saga"][currentLang],
      name: translations["Skapa saga"][currentLang],
      component: CreateView,
      meta: { header: true, greenButton: true, requiresAuth: true },
    },
    {
      path: translations["/köp-sagomynt"][currentLang],
      name: translations["Köp sagomynt"][currentLang],
      component: BuySagoTokensView,
      meta: { header: false, requiresAuth: true },
    },
    {
      path: translations["/bibliotek"][currentLang],
      name: translations["Sagor"][currentLang],
      component: LibraryView,
      meta: { header: true },
    },
    {
      path: translations["/författare"][currentLang],
      name: translations["Författare"][currentLang],
      component: AuthorsView,
      meta: { header: true },
    },
    {
      path: translations["/skapa-konto"][currentLang],
      name: translations["Skapa konto"][currentLang],
      component: CreateAccountView,
      meta: { header: false, requiresUnAuth: true },
    },
    {
      path: translations["/logga-in"][currentLang],
      name: translations["Logga in"][currentLang],
      component: LoginView,
      meta: { header: false, requiresUnAuth: true },
    },
    {
      path: translations["/glömt-lösenord"][currentLang],
      name: translations["Glömt lösenord"][currentLang],
      component: ForgottenPasswordView,
      meta: { header: false, requiresUnAuth: true },
    },
    {
      path: translations["/profil"][currentLang],
      name: translations["Profil"][currentLang],
      component: ProfileView,
      meta: { header: false, requiresAuth: true, authHeader: false },
    },
    {
      path: translations["/saga"][currentLang] + "/:id",
      name: translations["Saga"][currentLang],
      component: ClassicStoryView,
    },
    {
      path: translations["/pub-sagor"][currentLang] + "/:id",
      name: translations["Publik saga"][currentLang],
      component: PublicStoryView,
    },
    {
      path: translations["/delad"][currentLang] + "/:id",
      name: translations["Delad saga"][currentLang],
      component: SharedStoryView,
    },
    {
      path: translations["/mina-sagor"][currentLang] + "/:id",
      name: translations["Min saga"][currentLang],
      component: MyStoryView,
      meta: { header: false, requiresAuth: true },
    },
    {
      path: translations["/din-saga-skapas"][currentLang],
      name: translations["Din saga skapas"][currentLang],
      component: CreatingStoryView,
      meta: { header: false, requiresAuth: true },
    },
    {
      path: translations["/beställ-bok"][currentLang],
      name: translations["Beställ bok"][currentLang],
      component: BookOrderedView,
      meta: { header: false, requiresAuth: true },
    },
  ];
}

const router = createRouter({
  history: createWebHistory(),
  routes: generateRoutes(),
});

router.beforeEach((to, from, next) => {
  const currentLang = store.state.language;

  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const requiresUnAuth = to.matched.some(
    (record) => record.meta.requiresUnAuth
  );
  const isAuthenticated = auth.currentUser; // Using the modular approach

  if (requiresAuth && !isAuthenticated) {
    next(translations["/logga-in"][currentLang]);
  } else if (requiresUnAuth && isAuthenticated) {
    next(translations["/skapa-din-saga"][currentLang]);
  } else if (!to.matched.length) {
    next(translations["/skapa-din-saga"][currentLang]);
  } else {
    next();
  }
});

router.afterEach(() => {
  window.scrollTo(0, 0);
});

export function updateRoutes() {
  const newRoutes = generateRoutes();

  // Update the routes in the store
  store.commit("SET_ROUTES", newRoutes);

  // Remove all existing routes
  router.getRoutes().forEach((route) => {
    router.removeRoute(route.name);
  });

  // Add new routes
  newRoutes.forEach((route) => {
    router.addRoute(route);
  });
}

export default router;
