<template>
  <div class="bg-sagoblue w-full">
    <div class="flex justify-center items-center py-5">
      <a
        class="text-sagotextcolor hover:text-opacity-80"
        :href="translations['instagram'][currentLang]"
        target="_blank"
        rel="noopener noreferrer"
      >
        <fa-icon
          :icon="faInstagram"
          class="text-4xl p-3 text-sagotextcolor hover:opacity-80"
        />
      </a>
      <a
        class="text-sagotextcolor hover:text-opacity-80"
        :href="translations['facebook'][currentLang]"
        target="_blank"
        rel="noopener noreferrer"
      >
        <font-awesome-icon
          class="text-4xl p-3 text-sagotextcolor hover:opacity-80"
          :icon="['fab', 'facebook']"
        />
      </a>
    </div>

    <footer class="flex justify-center items-center py-5">
      <div class="text-sagotextcolor"></div>
    </footer>
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import translations from "@/trans";

library.add(faInstagram);

export default {
  name: "FooterComponent",
  components: {
    "fa-icon": FontAwesomeIcon,
  },
  data() {
    return {
      faInstagram, // this makes the icon available in the template
      translations,
    };
  },
  computed: {
    currentLang() {
      return this.$store.state.language;
    },
  },
};
</script>
