import { createStore } from "vuex";
import { auth, db } from "@/firebase";
import { onSnapshot, doc, setDoc } from "firebase/firestore";
import { updateRoutes } from "@/router";

export default createStore({
  state: {
    notifications: [],
    sagoTokens: 0,
    isWideScreen: window.innerWidth > 1280,
    language: "sv",
    routes: [],
  },
  mutations: {
    SET_WIDESCREEN(state, isWideScreen) {
      state.isWideScreen = isWideScreen;
    },
    ADD_NOTIFICATION(state, payload) {
      const id = new Date().getTime();

      state.notifications.push({
        id,
        message: payload.message,
        link: payload.link,
      });
    },
    REMOVE_NOTIFICATION(state, payload) {
      state.notifications = state.notifications.filter(
        (n) => n.id !== payload.id
      );
    },
    HIDE_NOTIFICATION(state, payload) {
      const notification = state.notifications.find((n) => n.id === payload.id);
      if (notification) {
        notification.visibility = false;
      }
    },
    SET_SAGOTOKENS(state, tokens) {
      state.sagoTokens = tokens;
    },
    SET_LANGUAGE(state, language) {
      state.language = language;
    },
    SET_ROUTES(state, newRoutes) {
      state.routes = newRoutes;
    },
  },
  actions: {
    listenToScreenEvents({ commit }) {
      window.addEventListener("resize", () => {
        commit("SET_WIDESCREEN", window.innerWidth > 640);
      });
    },
    addNotification({ commit }, payload) {
      commit("ADD_NOTIFICATION", payload);
    },
    removeNotification({ commit }, payload) {
      commit("REMOVE_NOTIFICATION", payload);
    },
    async updateLanguage({ commit }, language) {
      if (auth.currentUser) {
        const userRef = doc(db, "users", auth.currentUser.uid);

        try {
          // Update the user's language preference in Firestore
          await setDoc(userRef, { language: language }, { merge: true });

          // Commit the new language to the Vuex store
          commit("SET_LANGUAGE", language);

          // Update the routes
          updateRoutes();
        } catch (error) {
          console.error("Error updating language in Firestore:", error);
          // Handle the error appropriately
        }
      } else {
        // If the user is not logged in, you might want to handle this case differently
        // For example, you could store the language preference in localStorage
        localStorage.setItem("preferredLanguage", language);

        // Commit the new language to the Vuex store
        commit("SET_LANGUAGE", language);

        // Update the routes
        updateRoutes();
      }
    },
    checkLanguage({ commit }) {
      if (auth.currentUser) {
        const userRef = doc(db, "users", auth.currentUser.uid);
        onSnapshot(userRef, (doc) => {
          const userData = doc.data();
          if (userData && userData.language) {
            commit("SET_LANGUAGE", userData.language);
            updateRoutes();
          } else {
            // Set language based on domain ending
            const domain = window.location.hostname;
            let lang = "sv"; // default
            if (domain.endsWith(".pl")) {
              lang = "pl";
            } else if (domain.endsWith(".nu")) {
              lang = "sv";
            }
            commit("SET_LANGUAGE", lang);
            updateRoutes();

            // Optionally update the user document with this default
            setDoc(userRef, { language: lang }, { merge: true });
          }
        });
      } else {
        // Check for language preference in localStorage
        const storedLang = localStorage.getItem("preferredLanguage");
        let lang;

        if (storedLang) {
          lang = storedLang;
        } else {
          // If not in localStorage, set language based on domain
          const domain = window.location.hostname;
          lang = "sv"; // default
          if (domain.endsWith(".pl")) {
            lang = "pl";
          } else if (domain.endsWith(".nu")) {
            lang = "sv";
          }
        }
        commit("SET_LANGUAGE", lang);
        updateRoutes();
      }
    },
    listenToSagoTokens({ commit }) {
      auth.onAuthStateChanged((user) => {
        if (user) {
          const userDocRef = doc(db, "users", user.uid);
          onSnapshot(userDocRef, async (document) => {
            if (document.exists()) {
              commit("SET_SAGOTOKENS", document.data().sagoTokens);
            } else {
              console.log("No such document! Creating new one...");
              const userDocRef = doc(db, "users", user.uid);
              await setDoc(userDocRef, { sagoTokens: 100 });
            }
          });
        }
      });
    },
  },
});
