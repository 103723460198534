<template>
  <div id="app">
    <header>
      <HeaderComponent />
    </header>

    <NotificationComponent />
    <!-- This is where the component of the current route will be rendered -->
    <router-view class="pt-28" />

    <footer>
      <FooterComponent />
    </footer>
  </div>
</template>

<script>
import HeaderComponent from "@/components/global/HeaderComponent.vue";
import FooterComponent from "./components/global/FooterComponent.vue";
import NotificationComponent from "@/components/global/NotificationComponent.vue";
import "@/assets/css/tailwind.css";

export default {
  name: "App",
  components: {
    HeaderComponent,
    NotificationComponent,
    FooterComponent,
  },
  mounted() {
    this.$store.dispatch("listenToSagoTokens");
    this.$store.dispatch("listenToScreenEvents");
    this.$store.dispatch("checkLanguage");
  },
};
</script>
